import React, { useEffect } from "react";
import { navigate } from "gatsby";
import { toast } from "react-toastify";
import useAuth from "@components/hooks/useAuth";

const Component = () => {
    const { logout, loading, isAuthenticated } = useAuth();

    console.log(logout, loading, isAuthenticated);

    useEffect(() => {
        if (isAuthenticated && !loading) {
            (async () => {
                await logout();

                toast.info("Sesión cerrada exitosamente.", {
                    position: "bottom-center",
                    closeOnClick: true,
                    pauseOnHover: true,
                    autoClose: 2000,
                });
            })();
        }

        navigate("/");
    }, [loading, isAuthenticated, logout]);

    return <></>;
};

export default Component;
